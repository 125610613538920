/**
 - 닷 패드 제품들의 여러 수치 정보를 대응
 */
class DotPadInfo {

    /**
     - 닷패드 제품명
     - DotPad320
     - DotPad768
     */
    name;
    /**
     - 닷패드 종류
     - 320
     - 768
     */
    deviceKind;
    /**
     - 캔버스에 표현되는 칸 총 개수
     - 300 (DotPad320)
     - 768 (DotPad768)
     */
    cellTotal;
    /**
     - 캔버스에 표현되는 칸 총 개수
     - 300 (DotPad320)
     - 768 (DotPad768)
     */
    totalCell;
    /**
     - 삭제 예정
     - 캔버스에 표현되는 셀 총 높이 개수
     - 10 (DotPad320)
     - 16 (DotPad768)
     */
    totalCellHeight;
    /**
     - 캔버스 분면의 기준 셀 값 배열
     - [0, 60, 120, 1, 61, 121] (DotPad320)
     - [0, 96, 192, 1, 97, 193] (DotPad768)
     */
    indexCells;
    /**
     - 캔버스 분면의 기준 셀 값 배열
     - [0, 60, 120, 180, 1, 61, 121, 181] (DotPad320)
     - [0, 96, 192, 288, 1, 97, 193, 289] (DotPad768)
     */
    indexCellsFor8Pin;
    /**
     - 캔버스 가로 칸 개수(총)
     - 60 (DotPad320)
     - 96 (DotPad768)
     */
    width;
    /**
     - 캔버스 가로 칸 개수(절반)
     - 30 (DotPad320)
     - 48 (DotPad768)
     */
    halfWidth;
    /**
     - 캔버스 가로 칸 개수(2배)
     - 120 (DotPad320)
     - 192 (DotPad768)
     */
    doubleWidth;
    /**
     - 캔버스 가로 칸 개수(3배)
     - 180 (DotPad320)
     - 288 (DotPad768)
     */
    tripleWidth;
    /**
     - 캔버스 세로 칸 개수(총)
     - 40 (DotPad320)
     - 64 (DotPad768)
     */
    height;
    /**
     - 캔버스 세로 칸 개수(절반)
     - 20 (DotPad320)
     - 32 (DotPad768)
     */
    halfHeight;
    /**
     - 캔버스 면적(가로 * 세로)
     - 2400 (DotPad320)
     - 6144 (DotPad768)
     */
    totalArea;
    /**
     - 캔버스에 표현할 수 있는 셀 총 라인수
     - 10 (DotPad320)
     - 16 (DotPad768)
     */
    totalCellLine;
    /**
     - 캔버스 한 줄에 표현할 수 있는 총 셀
     - 20 (DotPad320)
     - 32 (DotPad768)
     */
    totalCellWidth;
    /**
     - 캔버스 점역 라인 줄바꿈 길이
     - 360 = 60(칸) * 6(윗라인과 띄울칸수) (DotPad320)
     - 480 = 96(칸) * 5(윗라인과 띄울칸수) (DotPad768)
     */
    lineBreakLength;
    /**
     - 캔버스 선택에 따라 페이지 설명 기능 사용 여부
     */
    isDescription;
    /**
     - 캔버스 선택에 따라 툴바 아이콘 표현 여부
     */
    isIcon;
    /**
     - 캔버스 사이즈에 따라 행렬로 배열 생성
     - 2400 (DotPad320)
     - 6144 (DotPad768)
     */
    totalMatrix;
    /**
     - 형식화 배열은 플랫폼의 바이트 순서를 따르는 8비트 부호 없는 정수의 배열을 생성
     - 2400 (DotPad320)
     - 6144 (DotPad768)
     */
    dtmData;
    /**
     - 16진수 총 길이
     - 600 = 2400 / 4 (DotPad320)
     - 1536 = 6144 / 4 (DotPad768)
     */
    totalHexLength;

    constructor(device) {
        this.initialize(device);
    }

    initialize(device) {
        switch (device) {
            case '768':
                this._setupDotPad768();
                break;
            default:
                this._setupDotPad320();
        }
    }

    _setupDotPad320() {
        this.name = "DotPad320";
        this.deviceKind = "320";
        this.width = 60;
        this.height = 40;
        this.cellTotal = this._calculateDivision(this._calculateMultiple(this.width, this.height), 8);
        this.totalCell = this._calculateDivision(this._calculateMultiple(this.width, this.height), 8);
        this.halfWidth = this._calculateDivision(this.width, 2);
        this.doubleWidth = this._calculateMultiple(this.width, 2);
        this.tripleWidth = this._calculateMultiple(this.width, 3);
        this.halfHeight = this._calculateDivision(this.height, 2);
        this.totalCellHeight = this._calculateDivision(this.height, 4);
        this.totalCellLine = this._calculateDivision(this.height, 4);
        this.totalCellWidth = this._calculateDivision(this.width, 3);
        this.indexCells = [0, this.width, this.doubleWidth, 1, this.width + 1, this.doubleWidth + 1];
        this.indexCellsFor8Pin = [0, this.width, this.doubleWidth, this.tripleWidth, 1, this.width + 1, this.doubleWidth + 1, this.tripleWidth + 1];
        this.totalArea = this._calculateTotalArea();
        this.totalMatrix = this._createMatrixArray(this.height, this.width);
        this.totalHexLength = this._calculateDivision(this.totalArea, 4);
        this.dtmData = new Uint8Array(this.totalCell);
        this.lineBreakLength = this._calculateMultiple(this.width, 6);
        this.isDescription = true;
        this.isIcon = true;
    }

    _setupDotPad768() {
        this.name = "DotPad768";
        this.deviceKind = "768";
        this.width = 96;
        this.height = 64;
        this.cellTotal = this._calculateDivision(this._calculateMultiple(this.width, this.height), 8);
        this.totalCell = this._calculateDivision(this._calculateMultiple(this.width, this.height), 8);
        this.halfWidth = this._calculateDivision(this.width, 2);
        this.doubleWidth = this._calculateMultiple(this.width, 2);
        this.tripleWidth = this._calculateMultiple(this.width, 3);
        this.halfHeight = this._calculateDivision(this.height, 2);
        this.totalCellHeight = this._calculateDivision(this.height, 4);
        this.totalCellLine = this._calculateDivision(this.height, 4);
        this.totalCellWidth = this._calculateDivision(this.width, 3);
        this.indexCells = [0, this.width, this.doubleWidth, 1, this.width + 1, this.doubleWidth + 1];
        this.indexCellsFor8Pin = [0, this.width, this.doubleWidth, this.tripleWidth, 1, this.width + 1, this.doubleWidth + 1, this.tripleWidth + 1];
        this.totalArea = this._calculateTotalArea();
        this.totalMatrix = this._createMatrixArray(this.height, this.width);
        this.totalHexLength = this._calculateDivision(this.totalArea, 4);
        this.dtmData = new Uint8Array(this.totalCell);
        this.lineBreakLength = this._calculateMultiple(this.width, 5);
        this.isDescription = false;
        this.isIcon = false;
    }

    _calculateDivision(num, div) {
        return num / div;
    }
    _calculateMultiple(num, multi) {
        return num * multi;
    }
    _calculateTotalArea() {
        return this.width * this.height;
    }
    _createMatrixArray(rows, columns) {
        var arr = new Array(rows);
        for (var i = 0; i < rows; i++) {
            arr[i] = new Array(columns);
        }
        return arr;
    }
}

export {
    DotPadInfo
}