<template>
    <component :is="layout" ref="layout"/>
</template>
<script>
import {defineAsyncComponent} from 'vue'

/**
 * 폰트 설정
 */
import '@/assets/css/font.css';
import FontFaceObserver from 'fontfaceobserver';

import '@/assets/css/common.css';

/**
 * 레이아웃 설정
 */
import WideLayout from './layouts/WideLayout.vue';
import {$session} from "dot-framework";
import store from "@/store";

const DefaultLayout = defineAsyncComponent(() => import("@/layouts/DefaultLayout.vue"));
const BlankLayout = defineAsyncComponent(() => import("@/layouts/BlankLayout.vue"));
const DriveLayout = defineAsyncComponent(() => import("@/layouts/DriveLayout.vue"));

export default {
    name: 'App',
    components:
        {
            DefaultLayout,
            BlankLayout,
            WideLayout,
            DriveLayout,
        },
    data() {
        return {
            layout: null,
            convertCheckInterval: null,
        }
    },
    created() {
        this.setFont();
        this.setHTMLLangAttribute();
        this.initLocale();
    },
    beforeMount() {
        document.getElementById("spinner").classList.add('d-none');
    },
    watch: {
        $route(to) {
            if (to.meta.layout !== undefined) {
                this.layout = to.meta.layout
            } else {
                this.layout = "User"
            }

            if ($session.getUserNo() !== 'GUEST') {
                // 이미 설정된 인터벌이 있으면 클리어
                if (this.convertCheckInterval) {
                    clearInterval(this.convertCheckInterval);
                }
                // 새로운 인터벌 설정
                this.convertCheckInterval = setInterval(() => {
                    if (this.checkPageVisibility()) {
                        this.loadAndShowPopups();
                    }
                }, 10000); // 10000 milliseconds = 10 seconds
            } else {
                if (this.convertCheckInterval) {
                    clearInterval(this.convertCheckInterval);
                }
            }
        },
        '$i18n.locale'() {
            this.setHTMLLangAttribute();
            // this.setHTMLDirAttribute();
        },
    },
    beforeDestroy() {
        // 컴포넌트가 파괴되기 전에 인터벌 클리어
        if (this.convertCheckInterval) {
            clearInterval(this.convertCheckInterval);
        }
    },
    methods: {
        setHTMLDirAttribute() {
            document.documentElement.setAttribute('dir', this.$i18n.locale === 'ar' ? 'rtl' : 'auto');
        },
        /**
         * 다국어 설정
         */
        setHTMLLangAttribute() {
            document.documentElement.setAttribute('lang', this.$i18n.locale);
        },
        initLocale() {
            const defaultLanguage = "en";
            const lang = navigator.language || navigator.userLanguage;
            const code = lang.substring(0, 2);
            const availableLocales = this.$i18n.availableLocales.includes(code);

            return this.$i18n.locale = availableLocales ? code : defaultLanguage;
        },
        /**
         * 폰트 설정
         * @see https://fonts.google.com/knowledge/glossary/fout
         */
        setFont() {
            const font = new FontFaceObserver('Helvetica Neue');

            font.load().then(function () {
                document.body.classList.add("fonts-loaded");
            }).catch(function () {
                console.log('Helvetica Neue failed to load.');
            });
        },

        async loadAndShowPopups() {
            try {
                const popupList = await store.dispatch("textbook/getConversionCompletePopup", $session.getUserNo());
                let message = "";

                if (popupList && popupList.length > 0) {
                    for (const popup of popupList) {
                        await store.dispatch("textbook/popupDisplayComplete", popup.DTM_NO);
                        if (popup.STATUS === "FAILED") {
                            message = this.$t('HTML 변환에 실패하였습니다.')
                        } else {
                            message = this.$t('HTML 변환이 완료되었습니다.')
                        }
                    }

                    this.showPopup(message);
                }
            } catch (error) {
                console.error("Failed to load and show popups:", error);
            }
        },

        showPopup(message) {
            const Toast = this.$swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
            });

            Toast.fire({
                html: `<swalCustomTag style="color: black;"><b>${message}</b></swalCustomTag>`,
            });
        },

        checkPageVisibility() {
            return document.visibilityState === "visible";
        }
    }
}
</script>

<style scoped>
</style>
