import { DotPadLine } from "./DotPadLine";

const RefreshType = {
    NONE: 'NONE',
    TYPE_A: 'TYPE_A',
    TYPE_B: 'TYPE_B',
    TYPE_C: 'TYPE_C',
};

const RefreshMode = {
    NONE: 'NONE',
    ALL_DISPLAY: 'ALL_DISPLAY',
    GRAPHIC_DISPLAY: 'GRAPHIC_DISPLAY',
    TEXT_DISPLAY: 'TEXT_DISPLAY',
    LIVE_DISPLAY: 'LIVE_DISPLAY',
};

const DisplayMode = {
    LINE: 'LINE',
    ALL: 'ALL'
}

class DotPadSendModule {
    constructor(dotPad) {
        this.dotPadLineList = [];
        this.dotPadTextCount = 1;
        this.dotPadGraphicLine = null; // 그래픽영역의 데이터를 한번에 보내기위한 객체
        this.dotCommandSendReady = true;
        this.sendTime = new Date();
        this.sendCnt = 0;
        this.dotPadLine = null;
        this.restart = false;
        this.currentIndex = 0;
        this.isFunctionRunning = false;
        this.dotPad = dotPad;

        this.refreshType = RefreshType.TYPE_C;
        this.refreshCount = 0;
        this.refreshItem = null;
        this.refreshLine = [];
        this.refreshMode = RefreshMode.NONE;

        this.displayMode = DisplayMode.LINE;
    }

    // 라인목록 초기화
    clearDotPadLine(){
        this.dotPadLineList = [];
    }

    // 닷패드 라인 추가
    addDotPadLine(rowCellSize, colCellSize) {
        this.dotPadLineList = [];

        // 20셀 세팅 (600:requestTime)
        this.dotPadLineList.push(new DotPadLine(0, 600, 20));
        
        // 디바이스 크기에 맞게 세팅 (600:requestTime)
        for (let i = 1; i <= rowCellSize; i++) {
            this.dotPadLineList.push(new DotPadLine(i, 600, colCellSize));
        }

        this.dotPadGraphicLine = new DotPadLine(1, 600, rowCellSize * colCellSize);
    }

    // 해당 라인에 커맨드 세팅
    setDotPadLineCommand(line, seqNum, startCellIndex, sendData) {
        if (this.refreshCount > 0) {
            for (const tempDotPadLine of this.dotPadLineList) {
                tempDotPadLine.setRequestReady(false);
                tempDotPadLine.setReceiveAck(true);
            }
            
            this.refreshCount = 0;
            this.currentIndex = 0;
        }
        
        if (this.refreshMode == RefreshMode.LIVE_DISPLAY && !this.refreshLine.contains(line)){
            this.refreshLine.append(line);
            this.dotPadLineList[line].initRefreshLiveData(startCellIndex, sendData);
        } else {
            this.dotPadLineList[line].setRefreshLiveData(startCellIndex, sendData);
        }

        this.dotPadLineList[line].setCommand(seqNum, startCellIndex, sendData);
    }

    // 발송준비 세팅
    setDotCommandSendReady(ready) {
        this.dotCommandSendReady = ready;
    }

    async refresh() {
        for (let i = 0; i < this.dotPadLineList.length; i++) {
            this.dotPadLineList[i].refresh();
        }

        await this.sendCommand();
    }

    async sendCommand(refresh = false) {
        if (!this.isFunctionRunning) {
            this.isFunctionRunning = true;
            this.currentIndex = 0;
            if (!refresh) {
                this.refreshCount = 0;
            }

            const cellType = this.dotPad.deviceInfo.cellType;
            const processNextLine = async () => {
                if (this.currentIndex < this.dotPadLineList.length) {
                    // 재시작일경우 값 초기화
                    if (this.restart) {
                        this.currentIndex = 0;
                        this.restart = false;
                        this.dotCommandSendReady = true;
                    } else {
                        this.dotPadLine = this.dotPadLineList[this.currentIndex];

                        // 발송해야하는지 확인
                        if (this.dotPadLine.getRequestReady() && this.dotCommandSendReady) {
                            // 발송 처리 
                            // 발송모드가 한번에 출력 && 그래픽영역부터 출력 && 재출력 상태가 아닐때
                            if (this.displayMode == DisplayMode.ALL && this.currentIndex >= this.dotPadTextCount && this.refreshCount == 0) {
                                //console.log(this.dotPadGraphicLine.lineData);
                                
                                // 현재 인덱스 발송상태로 변경
                                this.dotPadLine.setRequestReady(false);
                                this.dotPadLine.setReceiveAck(false);
                                
                                // console.log("REQ");
                                // console.log(new Date().toISOString());
                                await this.dotPad.writeCmdTest(this.dotPadGraphicLine.sendCommand());
                            } else {
                                // console.log("REQ");
                                // console.log(new Date().toISOString());
                                await this.dotPad.writeCmdTest(this.dotPadLine.sendCommand());
                            }

                            this.dotCommandSendReady = false;

                            // 시간체크 시작
                            this.sendTime = new Date();
                            this.sendCnt = 0;
                        }
                        // ACK 수신 대기중
                        else if (!this.dotPadLine.getReceiveAck()) {
                            // 시간체크
                            const nowTime = new Date() - this.sendTime;
                            if (nowTime > this.dotPadLine.getRequestTime()) {
                                // 재요청
                                if (this.sendCnt < 3) {
                                    // 재발송
                                    if (this.displayMode == DisplayMode.ALL && this.currentIndex >= this.dotPadTextCount && this.refreshCount == 0) {
                                        await this.dotPad.writeCmdTest(this.dotPadGraphicLine.sendCommand());
                                    } else {
                                        await this.dotPad.writeCmdTest(this.dotPadLine.sendCommand());
                                    }

                                    this.sendTime = new Date();
                                    this.sendCnt += 1;
                                } else {
                                    // 수신받은 것으로 처리
                                    this.dotPadLine.setReceiveAck(true);
                                    this.dotCommandSendReady = true;
                                }
                            }
                        } else if (this.displayMode == DisplayMode.ALL && this.currentIndex >= this.dotPadTextCount && this.refreshCount == 0) {
                            if (this.dotCommandSendReady) {
                                for (var i = this.currentIndex; i < this.dotPadLineList.length; i++) {
                                    this.dotPadLineList[i].setRequestReady(false);
                                    this.dotPadLineList[i].setReceiveAck(true);                        
                                }
                                this.dotPadLine = null;
                                this.currentIndex = this.dotPadLineList.length;
                            }
                        } else if (this.dotCommandSendReady) {
                            this.currentIndex += 1;
                        }
                    }

                    // DotPad320 D2셀의 경우 Refresh 처리 
                    if (cellType == "D2") {
                        this.refreshCommand();
                    }
                    setTimeout(processNextLine, 10); // 0.01초 지연 후 다음 반복 실행
                } else {
                    this.isFunctionRunning = false;
                }
            };

            await processNextLine();
        } else {
            this.restart = true;
        }
    }

    // Ack 세팅
    setDotPadLineReceiveAck(line, receiveAck) {
        const dotPadLine = this.dotPadLineList[line];
        if(dotPadLine){
            dotPadLine.setReceiveAck(receiveAck);
        }
    }

    // 재출력 타입 세팅
    setRefreshType(type){
        this.refreshType = type;
    }

    // 재출력
    refreshCommand() {
        if(this.dotPadLineList.length == this.currentIndex) {
            switch (this.refreshType) {
                case RefreshType.TYPE_A : this.refreshCommandFuncA(); break;
                case RefreshType.TYPE_B : this.refreshCommandFuncB(); break;
                case RefreshType.TYPE_C : this.refreshCommandFuncC(); break;
                default: break;
            }
        }
    }

    // 재출력 A타입
    refreshCommandFuncA() {
        // 전체출력후 재출력
        if (this.refreshCount < 3) {
            this.currentIndex = 0;
            
            for (const tempLine of this.dotPadLineList) {
                tempLine.refresh();
            }
            
            this.refreshCount += 1;
        }
    }

    // 재출력 B타입
    refreshCommandFuncB() {
        // 전체출력후 재출력
        if (this.refreshCount < 3) {
            this.currentIndex = 0;
            for (var i = 0; i <= 3; i++) {
                this.dotPadLineList[i].refresh();
            }
        } else if (this.refreshCount < 6) {
            this.currentIndex = 4;
            
            for (var i = 4; i <= 10; i++) {
                this.dotPadLineList[i].refresh();
            }
        } else {
            this.refreshMode = RefreshMode.NONE;
        }
        
        this.refreshCount += 1;
    }

    // 재출력 C타입
    refreshCommandFuncC() {
        // 재출력 작업이 등록되어 있는경우
        if (this.refreshItem != null) {
            return
        }

        switch (this.refreshMode) {
            case RefreshMode.ALL_DISPLAY : this.refreshCommandFuncB(); break;
            case RefreshMode.GRAPHIC_DISPLAY : this.graphicRefresh(); break;
            case RefreshMode.TEXT_DISPLAY : this.textRefresh(); break;
            case RefreshMode.LIVE_DISPLAY : this.liveRefresh(); break;
            default:break;
        }
    }

    graphicRefresh() {
        if (this.refreshCount < 3) {
            this.currentIndex = 1;
            

            for (let i = 1; i <= 3; i++) {
                this.dotPadLineList[i].refresh();
            }

            this.refreshCount += 1;
        } else if (this.refreshCount < 6) {
            this.refreshItem = () => {
                this.currentIndex = 4;

                for (let i = 4; i <= 10; i++) {
                    this.dotPadLineList[i].refresh();
                }

                this.refreshItem = null;
                this.refreshCount += 1;
                this.sendCommand(true);
            };

            setTimeout(this.refreshItem, 500);
        } else {
            this.refreshMode = RefreshMode.NONE;
        }
    }

    textRefresh(){
        if (this.refreshCount < 3) {
            this.refreshItem = () => {
                this.currentIndex = 0;
                this.dotPadLineList[0].refresh();
                
                this.refreshItem = null;
                this.refreshCount += 1;
                this.sendCommand(true);
            }
            
            setTimeout(this.refreshItem, 1500);
        } else {
            this.refreshMode = RefreshMode.NONE;
        }
    }

    liveRefresh(){
        if (this.refreshCount < 3) {
            this.refreshItem = () => {
                this.currentIndex = 0;
                
                for (const i of this.refreshLine) {
                    this.dotPadLineList[i].refresh(RefreshMode.LIVE_DISPLAY);
                }
                
                this.refreshItem = null;
                this.refreshCount += 1;
                this.sendCommand(true);
            }
            
            setTimeout(this.refreshItem, 1500);
        } else {
            this.refreshLine = [];
            this.refreshMode = RefreshMode.NONE;
        }
    }

    setRefreshMode(mode) {
        clearTimeout(this.refreshItem);
        this.refreshItem = null;
        
        
        if (this.refreshMode != RefreshMode.LIVE_DISPLAY && mode == RefreshMode.LIVE_DISPLAY) {
            this.refreshLine = [];
        }
        
        this.refreshCount = 0;
        if (this.refreshMode == RefreshMode.NONE) {
            this.refreshMode = mode;
            this.refreshLine = [];
            
        } else if (this.refreshMode != RefreshMode.ALL_DISPLAY && this.refreshMode != mode) {
            if (this.refreshMode == RefreshMode.GRAPHIC_DISPLAY) {
                if (mode == RefreshMode.TEXT_DISPLAY) {
                    this.refreshMode = RefreshMode.ALL_DISPLAY;
                }
            }
            else if (this.refreshMode == RefreshMode.TEXT_DISPLAY) {
                if (mode != RefreshMode.TEXT_DISPLAY) {
                    this.refreshMode = RefreshMode.ALL_DISPLAY;
                }
            }
            else if (this.refreshMode == RefreshMode.LIVE_DISPLAY) {
                if (mode == RefreshMode.TEXT_DISPLAY) {
                    this.refreshMode = RefreshMode.ALL_DISPLAY;
                } else if (mode == RefreshMode.GRAPHIC_DISPLAY) {
                    this.refreshMode = RefreshMode.GRAPHIC_DISPLAY;
                }
            }
        }
    }

    setDisplayMode(mode) {
        this.displayMode = mode;
    }

    getDisplayMode() {
        return this.displayMode;
    }
}

export {
    DotPadSendModule
}
