import { DotpadSDK } from "@/components/dtms-editor-v2.0/js/DotpadSDK.js";
import { Group, Rect, Line, Canvas, StaticCanvas } from "fabric";

const dotpadSDK = new DotpadSDK();
const NUMBER_OF_HORIZONTAL_PINS = 60;
const NUMBER_OF_VERTICAL_PINS = 40;

/**
 [Dot Canvas - 발표모드, Doc Cloud - Card 썸네일]
 캔버스에 올바르게 핀 세팅 하도록 캔버스 픽셀 크기 계산
 - param item
 - returns {*[]}
 */
function getPixelArray(item) {
    //console.log("[DTMSCanvas.js] - getPixelArray item ? ", item);
    dotpadSDK.setDotPadSDKInfo(item.dotPadInfo);
    const graphicData = item.graphic.data; // 600자 Hex String
    const byteArr = dotpadSDK.hexToBytes(graphicData); // 300 Bytes Array
    return _calculatePixelArray(byteArr, item.dotPadInfo);
}

// 캔버스 생성
function createCanvas(canvasId, canvasWidth, canvasHeight) {
    const canvas = new Canvas(canvasId);
    canvas.setDimensions({ width: canvasWidth, height: canvasHeight });

    return canvas;
}

function createStaticCanvas(canvasId, canvasWidth, canvasHeight) {
    let staticCanvas = null
    try {
        staticCanvas = new StaticCanvas(canvasId);
        staticCanvas.setDimensions({ width: canvasWidth, height: canvasHeight });
    }
    catch (e) {
        console.log(e, canvasId)
    }

    return staticCanvas;
}

// 캔버스에 그리드 그리기
function drawGridOnTheCanvas(canvasWidth, canvasHeight) {
    const gridLines = [];
    const gridOptions = {selectable: false, stroke: '#cccccc'};

    for (let row = 0; row < NUMBER_OF_VERTICAL_PINS; row++) {
        const y = (row / NUMBER_OF_VERTICAL_PINS) * canvasHeight;
        gridLines.push(new Line([0, y, canvasWidth, y], gridOptions));
    }

    for (let column = 0; column < NUMBER_OF_HORIZONTAL_PINS; column++) {
        const x = (column / NUMBER_OF_HORIZONTAL_PINS) * canvasWidth;
        gridLines.push(new Line([x, 0, x, canvasHeight], gridOptions));
    }

    return new Group(gridLines, {
        selectable: false,
        evented: false,
    });
}

/**
 캔버스에 그리드 그리기
 - params{dotPadInfo, pixelArr, canvasWidth, canvasHeight}
 - returns {Group}
 */
function drawGridOnTheCanvasForChangeTest(params) {
    const dotPadInfo = params.dotPadInfo;
    const horizontalPins = dotPadInfo ? dotPadInfo.width : NUMBER_OF_HORIZONTAL_PINS;        //HORIZONTAL_PINS = 768(96), 320(60);
    const verticalPins = dotPadInfo ? dotPadInfo.height : NUMBER_OF_VERTICAL_PINS;         //VERTICAL_PINS = 768(64), 320(40);
    const canvasHeight = params.canvasHeight;
    const canvasWidth = params.canvasWidth;
    const gridLines = [];
    const gridOptions = {selectable: false, stroke: '#cccccc'};
    for (let row = 0; row < verticalPins; row++) {
        const y = (row / verticalPins) * canvasHeight;
        gridLines.push(new Line([0, y, canvasWidth, y], gridOptions));
    }

    for (let column = 0; column < horizontalPins; column++) {
        const x = (column / horizontalPins) * canvasWidth;
        gridLines.push(new Line([x, 0, x, canvasHeight], gridOptions));
    }

    return new Group(gridLines, {
        selectable: false,
        evented: false,
    });
}

// 캔버스에 촉각 정보 그리기
function drawTactileDataOnTheCanvas(pixelArr, canvasWidth, canvasHeight) {
    const pinW = canvasWidth / NUMBER_OF_HORIZONTAL_PINS;
    const pinH = canvasHeight / NUMBER_OF_VERTICAL_PINS;
    const array = [];
    let index = 0;

    for (let i = 0; i < NUMBER_OF_VERTICAL_PINS; i++) {
        for (let j = 0; j < NUMBER_OF_HORIZONTAL_PINS; j++) {
            if (pixelArr[index]) {
                const pin = new Rect({
                    fill: 'black',
                    left: pinW * j,
                    top: pinH * i,
                    width: pinW,
                    height: pinH,
                    hasBorders: false,
                    hasControls: false,
                    hasRotatingPoint: false,
                    lockMovementX: true,
                    lockMovementY: true,
                    selectable: false,
                    hoverCursor: "default",
                });
                array.push(pin);
            }
            index++;
        }
    }

    return new Group(array);
}

/**
   캔버스에 촉각 정보 그리기
 - params{dotPadInfo, pixelArr, canvasWidth, canvasHeight}
 - returns {Group}
 */
function drawTactileDataOnTheCanvasForChangeTest(params) {
    //console.log("[DTMSCanvas.js] - drawTactileDataOnTheCanvasForChangeTest params ? ", params);
    const dotPadInfo = params.dotPadInfo;
    const pixelArr = params.pixelArr;
    const horizontalPins = dotPadInfo ? dotPadInfo.width : NUMBER_OF_HORIZONTAL_PINS;        //HORIZONTAL_PINS = 768(90), 320(60);
    const verticalPins = dotPadInfo ? dotPadInfo.height : NUMBER_OF_VERTICAL_PINS;         //VERTICAL_PINS = 768(64), 320(40);
    const pinW = params.canvasWidth / horizontalPins;
    const pinH = params.canvasHeight / verticalPins;
    const array = [];
    let index = 0;

    for (let i = 0; i < verticalPins; i++) {
        for (let j = 0; j < horizontalPins; j++) {
            if (pixelArr[index]) {
                const pin = new Rect({
                    fill: 'black',
                    left: pinW * j,
                    top: pinH * i,
                    width: pinW,
                    height: pinH,
                    hasBorders: false,
                    hasControls: false,
                    hasRotatingPoint: false,
                    lockMovementX: true,
                    lockMovementY: true,
                    selectable: false,
                    hoverCursor: "default",
                });
                array.push(pin);
            }
            index++;
        }
    }

    return new Group(array);
}

function getVoxel() {
  return {
    NUMBER_OF_HORIZONTAL_PINS,
    NUMBER_OF_VERTICAL_PINS,
  }
}

// 텍타일 데이터의 좌표값으로 인덱스를 구하는 함수
function calculateIndexFromRect(rect, canvasWidth, canvasHeight) {
  const pinW = canvasWidth / NUMBER_OF_HORIZONTAL_PINS;
  const pinH = canvasHeight / NUMBER_OF_VERTICAL_PINS;

  // rect의 좌표를 가져옴
  const left = rect.left;
  const top = rect.top;

  // 해당 좌표의 행과 열을 계산
  const row = Math.floor(top / pinH);
  const col = Math.floor(left / pinW);

  // 현재 인덱스 번호 계산
  const index = row * NUMBER_OF_HORIZONTAL_PINS + col;

  return index;
}

function _calculatePixelArray(byteArr, dotPadInfo) {
    const result = [];
    for (let i = 0; i < byteArr.length; i++) {
        const start_index = Math.floor(i / dotPadInfo.halfWidth) * dotPadInfo.width * 4 + (i % dotPadInfo.halfWidth) * 2;
        result[start_index] = !!(byteArr[i] & (0x01 << 0));
        result[start_index + dotPadInfo.width] = !!(byteArr[i] & (0x01 << 1));
        result[start_index + dotPadInfo.doubleWidth] = !!(byteArr[i] & (0x01 << 2));
        result[start_index + dotPadInfo.tripleWidth] = !!(byteArr[i] & (0x01 << 3));
        result[start_index + 1] = !!(byteArr[i] & (0x01 << 4));
        result[start_index + dotPadInfo.width + 1] = !!(byteArr[i] & (0x01 << 5));
        result[start_index + dotPadInfo.doubleWidth + 1] = !!(byteArr[i] & (0x01 << 6));
        result[start_index + dotPadInfo.tripleWidth + 1] = !!(byteArr[i] & (0x01 << 7));
    }
    return result;
}

export default {
    createCanvas,
    createStaticCanvas,
    drawGridOnTheCanvas,
    drawGridOnTheCanvasForChangeTest,
    drawTactileDataOnTheCanvas,
    drawTactileDataOnTheCanvasForChangeTest,
    getPixelArray,
    getVoxel,
}
