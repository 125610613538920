import {DotPadSendModule} from "./DotPadSendModule";
import {DotpadResize} from "./DotpadResize";
import {DotPadInfo} from "./DotPadInfo";

class DotpadSDK {  // eslint-disable-line no-unused-vars
    constructor(dotPad) {
        //if (DotpadSDK_Instance) return DotpadSDK_Instance;
        this.VOXEL_ROW_NUM = 40;                               // 40(닷패드320) || 64(닷패드768)
        this.VOXEL_COL_NUM = 60;                               // 60(닷패드320) || 96(닷패드768)
        this.LINE_HEIGHT = 20;
        this.DOT_CELL_PIN = 8;
        this.dot_Matrix_All = this.create2DArray(this.VOXEL_ROW_NUM, this.VOXEL_COL_NUM);
        this.dtm_Data = new Uint8Array(300);        // 2400(60x40) / 8 = 300, 6144(96x64) / 8 = 768
        this.length = 10                                       // 디바이스 셀 ROW 수 10(300) || 16(768)
        this.numCell = 30                                      // 디바이스 셀 COL 수 30(300) || 48(768)
        this.stringwithPadding = "";
        this.indexPadding = 1;
        this.SubString = "";
        this.writeString_Wrap = "";
        this.writeString = "";
        this.res = false;
        this.dotPad = dotPad;
        this.dotPadSendModule = new DotPadSendModule(dotPad);
        this.lineCount = 0;
        this.cellTotal = 300;
        this.DOT_PAD_INFO = {}
        if (dotPad) {
            this.dotPadSendModule.clearDotPadLine();
            this.dotPadSendModule.addDotPadLine(this.length, this.numCell);
            dotPad.setDotPadSendModule(this.dotPadSendModule);
        }
    }

    /**
     캔버스 정보가 고정값(40, 60 -> 64, 90 등)이 였던 것을 닷패드 정보에 따라 변경
     - param dotPadInfo{cellTotal, height, width}
     */
    setDotPadSDKInfo(dotPadInfo) {
        this.DOT_PAD_INFO = new DotPadInfo(dotPadInfo?.totalCell + '');
        // 추후 변수 제거해야함
        this.cellTotal = this.DOT_PAD_INFO.totalCell;
        this.VOXEL_ROW_NUM = this.DOT_PAD_INFO.height;
        this.VOXEL_COL_NUM = this.DOT_PAD_INFO.width;
        this.dot_Matrix_All = this.DOT_PAD_INFO.totalMatrix;
        this.dtm_Data = this.DOT_PAD_INFO.dtmData;
        this.length = this.DOT_PAD_INFO.totalCellLine;
        this.numCell = this.DOT_PAD_INFO.halfWidth;
        this.LINE_HEIGHT = this.DOT_PAD_INFO.halfHeight;

        if (this.dotPad) {
            this.dotPadSendModule.clearDotPadLine();
            this.dotPadSendModule.addDotPadLine(this.length, this.numCell);
            this.dotPad.setDotPadSendModule(this.dotPadSendModule);
        }
    }

    create2DArray(rows, columns) {
        var arr = new Array(rows);
        for (var i = 0; i < rows; i++) {
            arr[i] = new Array(columns);
        }
        return arr;
    }

    toHexString(byteArray) {
        return Array.from(byteArray, function (byte) {
            return ('0' + (byte & 0xFF).toString(16)).slice(-2);
        }).join('')
    }

    decimalToHex(d, padding) {
        var hex = Number(d).toString(16);
        padding = typeof (padding) === "undefined" || padding === null ? padding = 2 : padding;

        while (hex.length < padding) {
            hex = "0" + hex;
        }

        return hex;
    }

    // Convert a hex string to a byte array
    hexToBytes(hex) {
        const bytes = [];

        try {
            for (let c = 0; c < hex.length; c += 2)
                bytes.push(parseInt(hex.substring(c, c + 2), 16));
        } catch (e) {
            console.error(e);
        }

        return bytes;
    }

    /**
     * Convert a hex string to an ArrayBuffer.
     *
     * @param {string} hexString - hex representation of bytes
     * @return {ArrayBuffer} - The bytes in an ArrayBuffer.
     */
    hexStringToArrayBuffer(hexString) {
        // remove the leading 0x
        hexString = hexString.replace(/^0x/, '');

        // ensure even number of characters
        if (hexString.length % 2 != 0) {
            console.log('WARNING: expecting an even number of characters in the hexString');
        }

        // check for some non-hex characters
        var bad = hexString.match(/[G-Z\s]/i);
        if (bad) {
            console.log('WARNING: found non-hex characters', bad);
        }

        // split the string into pairs of octets
        var pairs = hexString.match(/[\dA-F]{2}/gi);

        // convert the octets to integers
        var integers = pairs.map(function (s) {
            return parseInt(s, 16);
        });

        var array = new Uint8Array(integers);
        // console.log(array);

        return array.buffer;
    }

    /**
     * Converts a 600-character hex string to an array of 2400 Boolean values.
     *
     * @param { string } hexString - A 600-character hex string (1 hex = 4 bits).
     * @return { boolean[] } - An array of 2400 Boolean values.
     */
    hexToBooleanArray = (hexString) => {
        // 300 Bytes Array
        const byteArray = this.hexToBytes(hexString);       //byteArray length는 닷 패드 설정에 따라 300, 768 셀로 나뉨
        const booleanArray = new Array(byteArray.length * 8).fill(false);
        /** @To-do 닷패드 모델에 따른 사이즈 계산 함수 추가 */
        const canvasWidth = byteArray.length === 300 ? 60 : 96;
        const canvasWidthHalf = byteArray.length === 300 ? 30 : 48;
        // Since there are eight pins of 300 cells, it will be set as below
        for (let i = 0; i < byteArray.length; i++) {
            const startIndex = Math.floor(i / canvasWidthHalf) * canvasWidth * 4 + (i % canvasWidthHalf) * 2;
            for (let bit = 0; bit < 8; bit++) {
                const index = startIndex + (bit % 4) * canvasWidth + Math.floor(bit / 4);
                booleanArray[index] = !!(byteArray[i] & (1 << bit));
            }
        }
        //console.log("[DotpadSDK.js] - hexToBooleanArray.length ? ", booleanArray.length);
        return booleanArray;
    };

    checksum(test_data) {
        var check = 0xA5;

        for (var i = 0; i < test_data.length; i++) {
            check = check ^ test_data[i]
            //console.log(i, test_data[i+4].toString(16), check.toString(16));
        }
        let res = ('0' + (check & 0xFF).toString(16)).slice(-2);
        return res;
    }

    /**
     Make a Data set using Application's Canvas Data(voxels: Array<Array<Bool>>).
     - warning: None.
     - parameter deviceType: DeviceType
     - parameter voxels: Array<Array<Bool>>
     - returns:
     */
    Load_mapFile(voxels) {
        //console.log("[DotpadSDK.js] - Load_mapFile this.VOXEL_ROW_NUM ? ", this.VOXEL_ROW_NUM, ", this.VOXEL_COL_NUM ? ", this.VOXEL_COL_NUM);
        let order = 0
        for (let i = 0; i < this.VOXEL_ROW_NUM; i++) {
            for (let j = 0; j < this.VOXEL_COL_NUM; j++) {
                if (voxels[order] == true) {
                    this.dot_Matrix_All[i][j] = 0x01
                } else {
                    this.dot_Matrix_All[i][j] = 0x00
                }
                order++;
            }
        }
        //console.log('this.dot_Matrix_All : ' + this.dot_Matrix_All.length + ", " + this.dot_Matrix_All[0].length + " : " + this.dot_Matrix_All);
    }

    /**
     Make a One Cell Data.
     - warning: None.
     - parameter cell_ID: Int
     - returns: None
     */
    Sub_dot_Cell_Matrix(cell_ID) {
        let _dot_Cell = this.create2DArray(4, 2);
        _dot_Cell[0][0] = this.dot_Matrix_All[parseInt(cell_ID / this.DOT_PAD_INFO.halfWidth) * 4][(cell_ID % this.DOT_PAD_INFO.halfWidth) * 2];
        _dot_Cell[0][1] = this.dot_Matrix_All[parseInt(cell_ID / this.DOT_PAD_INFO.halfWidth) * 4][(cell_ID % this.DOT_PAD_INFO.halfWidth) * 2 + 1];
        _dot_Cell[1][0] = this.dot_Matrix_All[parseInt(cell_ID / this.DOT_PAD_INFO.halfWidth) * 4 + 1][(cell_ID % this.DOT_PAD_INFO.halfWidth) * 2];
        _dot_Cell[1][1] = this.dot_Matrix_All[parseInt(cell_ID / this.DOT_PAD_INFO.halfWidth) * 4 + 1][(cell_ID % this.DOT_PAD_INFO.halfWidth) * 2 + 1];

        _dot_Cell[2][0] = this.dot_Matrix_All[parseInt(cell_ID / this.DOT_PAD_INFO.halfWidth) * 4 + 2][(cell_ID % this.DOT_PAD_INFO.halfWidth) * 2];
        _dot_Cell[2][1] = this.dot_Matrix_All[parseInt(cell_ID / this.DOT_PAD_INFO.halfWidth) * 4 + 2][(cell_ID % this.DOT_PAD_INFO.halfWidth) * 2 + 1];
        _dot_Cell[3][0] = this.dot_Matrix_All[parseInt(cell_ID / this.DOT_PAD_INFO.halfWidth) * 4 + 3][(cell_ID % this.DOT_PAD_INFO.halfWidth) * 2];
        _dot_Cell[3][1] = this.dot_Matrix_All[parseInt(cell_ID / this.DOT_PAD_INFO.halfWidth) * 4 + 3][(cell_ID % this.DOT_PAD_INFO.halfWidth) * 2 + 1];

        return _dot_Cell;
    }
    /**
     Transform One Cell Data to Byte Value.
     - warning: None.
     - parameter sub_Cell: [[UInt8]]
     - returns: [[UInt8]]
     */
    Get_dot_Cell_Byte(sub_Cell) {
        var cell_Byte = 0x0

        cell_Byte = ((sub_Cell[0][0]) + ((sub_Cell[0][1] << 4) & 0xff) + ((sub_Cell[1][0] << 1) & 0xff));
        cell_Byte = cell_Byte + (((sub_Cell[1][1] << 5) & 0xff) + ((sub_Cell[2][0] << 2) & 0xff));
        cell_Byte = cell_Byte + (((sub_Cell[2][1] << 6) & 0xff) + ((sub_Cell[3][0] << 3) & 0xff));
        cell_Byte = cell_Byte + (((sub_Cell[3][1] << 7) & 0xff));

        //console.log('[DotpadSDK.js] - Get_dot_Cell_Byte - cell_Byte : ' + cell_Byte);
        return cell_Byte;
    }
    /**
     DTM PREFIX 포멧 없이 DTM 파일 생성
     */
    Make_DTM_Data() {

        var _dot_Cell = this.create2DArray(4, 2);
        for (let Cell_ID = 0; Cell_ID < this.cellTotal; Cell_ID++) {
            _dot_Cell = this.Sub_dot_Cell_Matrix(Cell_ID);
            this.dtm_Data[Cell_ID] = this.Get_dot_Cell_Byte(_dot_Cell);
        }
        this.writeString = this.toHexString(this.dtm_Data);
    }

    /**
     Send Data of Application's Canvas Data to dotPad_Communication.
     - warning: None.
     - parameter
     - returns: None.
     */
    async sendPixelPattern() {
        await this.sendMessage(this.writeString);
        //print("[DotPad_ProcessData] sendPixelPattern : Mobile to Pad : \(dtm_Data.bytesToHex(spacing: ""))")
    }

    async sendLine(i, mode) {
        const commandCode = mode === "GRAPHIC" ? "00" : "80";
        let step = i - 1;
        this.SubString = this.stringwithPadding.substring(step * this.numCell * 2, ((step + 1) * this.numCell * 2));
        this.dotPadSendModule.setDotPadLineCommand(i, commandCode, "00", this.SubString);
        this.dotPadSendModule.setRefreshMode("GRAPHIC_DISPLAY");
        //await this.dotPadSendModule.sendCommand();
    }

    /**
     * Sends data to the DotPad display graphic area through the `dotPad_Communication`.
     *
     * @async
     * @param { string } writeString
     * @param { "GRAPHIC" | "TEXT" } mode
     * @returns { Promise<void> }
     */
    async sendMessage(writeString, mode = "GRAPHIC") { // eslint-disable-line no-unused-vars
        this.stringwithPadding = writeString

        if (!this.stringwithPadding) return
        if (this.stringwithPadding.length !== this.length * this.numCell * 2) return

        for (let i = 1; i <= this.length; i++) {
            await this.sendLine(i, mode)
        }

        // 전체 데이터를 한번에 보내기 위해 세팅
        this.dotPadSendModule.dotPadGraphicLine.setCommand("00", 0, this.stringwithPadding);

        await this.dotPadSendModule.sendCommand();
    }

    async sendText(textString, index) {
        if (this.dotPad.deviceInfo.deviceName === "DPA768A") return
        if (textString.length < 1) return

        let maxIndex = Math.ceil(textString.length / 40)
        this.SubString = index === (maxIndex - 1)
            ? textString.substring(index * 20 * 2)
            : textString.substring(index * 20 * 2, (index + 1) * 20 * 2)

        if (this.dotPad != null && this.dotPad.connected) {
            this.dotPadSendModule.setDotPadLineCommand(0, "80", "00", this.SubString)
            this.dotPadSendModule.setRefreshMode("TEXT_DISPLAY")
            await this.dotPadSendModule.sendCommand()

            return true
        }
    }

    downAllCell() {
        this.sendMessage("00".repeat(300));
        this.sendText("00".repeat(20), 0);
    }

    /**
     * @param brailleText
     * @returns {string}
     */
    convertGraphicToTextHex(brailleText) {
        const splitStrings = this.hexToBinary(brailleText).match(/.{1,8}/g);
        const swappedStrings = splitStrings.map((subStr, index) => {
            const pin1 = subStr.substring(7, 8);
            const pin2 = subStr.substring(6, 7);
            const pin3 = subStr.substring(5, 6);
            const pin4 = subStr.substring(4, 5);
            const pin5 = subStr.substring(3, 4);
            const pin6 = subStr.substring(2, 3);
            const pin7 = subStr.substring(1, 2);
            const pin8 = subStr.substring(0, 1);

            return pin8 + pin4 + pin7 + pin6 + pin5 + pin3 + pin2 + pin1;
        });
        // 3. 조인하고 다시 16진수로 변환
        return this.binaryToHex(swappedStrings.join(""));
    }

    /**
     * @param tactileLetters
     * @param {number} numberOfPins
     * @returns {string}
     */
    changeTactileLettersGraphicDisplayText(brailleText) {
        const splitStrings = this.hexToBinary(brailleText).match(/.{1,8}/g);
        const swappedStrings = splitStrings.map((subStr) => {
            return subStr.substring(0, 1) + subStr.substring(2, 5) + subStr.substring(1, 2) + subStr.substring(5);
        });
        // 3. 조인하고 다시 16진수로 변환
        return this.binaryToHex(swappedStrings.join(""));
    }

    hexToBinary(hex) {
        let binary = "";
        for (let i = 0; i < hex.length; i++) {
            const decimal = parseInt(hex[i], 16);
            const fourBits = decimal.toString(2).padStart(4, "0");
            binary += fourBits;
        }
        return binary;
    }

    binaryToHex(binary) {
        let hex = "";
        let i = 0;
        while (i < binary.length) {
            const fourBits = binary.substring(i, i + 4);
            const decimal = parseInt(fourBits, 2);
            hex += decimal.toString(16).toUpperCase();
            i += 4;
        }
        return hex;
    }

    /**
     * @param brailleText
     * @param {number} numberOfPins // 6 dot / 8 dot
     * @param {array} drawPoints
     * @returns {string}
     */
    changeGraphicDisplayMultiline(brailleText, numberOfPins, drawPoints) {
        const splitStrings = this.hexToBinary(brailleText).match(/.{1,8}/g);
        this.lineCount = 0;
        let tempDotCellLineData = this.createTempDotCellLineData(splitStrings, numberOfPins);
        let dotCellLineData = [];
        const drawPointHandlers = {
            '0000': this.handle0000.bind(this),
            '0001': this.handle0001.bind(this),
            '0011': this.handle0011.bind(this),
            '0111': this.handle0111.bind(this),
            '1000': this.handle1000.bind(this),
            '1001': this.handle1001.bind(this),
            '1011': this.handle1011.bind(this),
            '1100': this.handle1100.bind(this),
            '1101': this.handle1101.bind(this),
            '1110': this.handle1110.bind(this),
            '1111': this.handle1111.bind(this),
        }

        drawPoints.forEach((drawPointArr) => {
            let drawPoint = drawPointArr.join().replaceAll(',', '');
            if (drawPointHandlers[drawPoint]) {
                drawPointHandlers[drawPoint](tempDotCellLineData, dotCellLineData, numberOfPins);
            }
        });

        // 3. 조인하고 다시 16진수로 변환
        return this.binaryToHex(dotCellLineData.join(""));
    }

    /**
     * Before applying dot cell data blank
     * @param {array} splitStrings
     * @param {number} numberOfPins
     * @returns {array}
     */
    createTempDotCellLineData(splitStrings, numberOfPins) {
        //console.log("[DotpadSDK.js] - createTempDotCellLineData splitStrings ?", splitStrings);
        let dotCellLineData = [];
        let rearrangeBits = [];
        splitStrings.forEach((subStr, index) => {
            const tempRearrangeBits = this.rearrangeBits(subStr, numberOfPins, index, rearrangeBits);
            //console.log("[DotpadSDK.js] - createTempDotCellLineData this.LINE_HEIGHT ?", this.LINE_HEIGHT);

            if ((index + 1) % this.LINE_HEIGHT == 0) {          //this.LINE_HEIGHT 20(320) || 32(768)
                dotCellLineData.push(tempRearrangeBits);
                rearrangeBits = [];
            }
        });
        return dotCellLineData;
    }

    /**
     * rearrange the bit
     * @param {string} subStr
     * @param {number} numberOfPins
     * @param {number} index
     * @param {array} rearrangeBits
     * @returns {rearrangeBits}
     */
    rearrangeBits(subStr, numberOfPins, index, rearrangeBits) {
        // 1. 짝수 번째 텍스트 7, 8번 핀을 제외한 3비트씩 앞뒤로 위치를 바꾸고 8비트만큼 "0"으로 채움(6점식)
        //    짝수 번째 텍스트 7, 8번 핀을 포함한 3비트씩 앞뒤로 위치를 바꾸고 8비트만큼 "0"으로 채움(8점식)
        const number7Pin = numberOfPins === this.DOT_CELL_PIN ? subStr.substring(0, 1) : "0";
        const number8Pin = numberOfPins === this.DOT_CELL_PIN ? subStr.substring(1, 2) : "0";
        const bitsFromPins1To3 = subStr.substring(2, 5);
        const bitsFromPins4To6 = subStr.substring(5);
        if (index % 2 === 1) {
            rearrangeBits.push(number8Pin + bitsFromPins4To6 + "0000");
            rearrangeBits.push("0000" + number7Pin + bitsFromPins1To3);
        } else {
            rearrangeBits.push(number7Pin + bitsFromPins1To3 + number8Pin + bitsFromPins4To6);
        }

        return rearrangeBits;
    }

    handle0000(dotCellLineData) {
        let tempData = [];
        for (let i = 0; i < this.numCell; i++) {
            tempData.push("00000000");
        }
        dotCellLineData.push(tempData);
    }

    handle1111(tempDotCellLineData, dotCellLineData) {
        if (tempDotCellLineData[this.lineCount]) {
            tempDotCellLineData[this.lineCount].forEach(data => {
                dotCellLineData.push(data);
            });
        }
        this.lineCount++;
    }

    handle0001(tempDotCellLineData, dotCellLineData) {
        if (tempDotCellLineData[this.lineCount]) {
            tempDotCellLineData[this.lineCount].forEach(data => {
                dotCellLineData.push(data.substring(3, 4) + "000" + data.substring(7, 8) + "000");
            });
        }
    }

    handle0011(tempDotCellLineData, dotCellLineData) {
        if (tempDotCellLineData[this.lineCount]) {
            tempDotCellLineData[this.lineCount].forEach(data => {
                dotCellLineData.push(data.substring(2, 4) + "00" + data.substring(6, 8) + "00");
            });
        }
    }

    handle0111(tempDotCellLineData, dotCellLineData, numberOfPins) {
        if (tempDotCellLineData[this.lineCount]) {
            tempDotCellLineData[this.lineCount].forEach(data => {
                dotCellLineData.push(data.substring(1, 4) + "0" + data.substring(5, 8) + "0");
            });
        }
        if (numberOfPins != this.DOT_CELL_PIN) {
            this.lineCount++;
        }
    }

    handle1000(tempDotCellLineData, dotCellLineData, numberOfPins) {
        if (numberOfPins != this.DOT_CELL_PIN) {
            if (tempDotCellLineData[this.lineCount]) {
                tempDotCellLineData[this.lineCount].forEach(data => {
                    dotCellLineData.push("000" + data.substring(1, 2) + "000" + data.substring(5, 6));
                });
            }
        } else {
            if (tempDotCellLineData[this.lineCount]) {
                tempDotCellLineData[this.lineCount].forEach(data => {
                    dotCellLineData.push("000" + data.substring(0, 1) + "000" + data.substring(4, 5));
                });
            }
        }
        this.lineCount++;
    }

    handle1001(tempDotCellLineData, dotCellLineData, numberOfPins) {
        if (tempDotCellLineData[this.lineCount]) {
            let tempData1 = [], tempData2 = [], tempData3 = [], tempData4 = [];
            if (numberOfPins != this.DOT_CELL_PIN) {
                tempDotCellLineData[this.lineCount].forEach(data => {
                    tempData1.push(data.substring(1, 2));
                    tempData2.push(data.substring(5, 6));
                });
            } else {
                if (tempDotCellLineData[this.lineCount]) {
                    tempDotCellLineData[this.lineCount].forEach(data => {
                        tempData1.push(data.substring(0, 1));
                        tempData2.push(data.substring(4, 5));
                    });
                }
            }
            if (tempDotCellLineData[this.lineCount + 1]) {
                tempDotCellLineData[this.lineCount + 1].forEach(data => {
                    tempData3.push(data.substring(3, 4) + "00");
                    tempData4.push(data.substring(7, 8) + "00");
                });
            } else {
                tempDotCellLineData[this.lineCount].forEach(() => {
                    tempData3.push("000");
                    tempData4.push("000");
                });
            }
            tempData1.forEach((data, i) => {
                dotCellLineData.push(tempData3[i] + data + tempData4[i] + tempData2[i]);
            });
        }
        this.lineCount++;
    }

    handle1011(tempDotCellLineData, dotCellLineData) {
        if (tempDotCellLineData[this.lineCount]) {
            let tempData1 = [], tempData2 = [], tempData3 = [], tempData4 = [];
            tempDotCellLineData[this.lineCount].forEach(data => {
                tempData1.push(data.substring(0, 1));
                tempData2.push(data.substring(4, 5));
            });
            if (tempDotCellLineData[this.lineCount + 1]) {
                tempDotCellLineData[this.lineCount + 1].forEach(data => {
                    tempData3.push(data.substring(2, 4) + "0");
                    tempData4.push(data.substring(6, 8) + "0");
                });
            } else {
                tempDotCellLineData[this.lineCount].forEach(() => {
                    tempData3.push("000");
                    tempData4.push("000");
                });
            }
            tempData1.forEach((data, i) => {
                dotCellLineData.push(tempData3[i] + data + tempData4[i] + tempData2[i]);
            });
        }
        this.lineCount++;
    }

    handle1100(tempDotCellLineData, dotCellLineData, numberOfPins) {
        if (numberOfPins != this.DOT_CELL_PIN) {
            if (tempDotCellLineData[this.lineCount]) {
                tempDotCellLineData[this.lineCount].forEach(data => {
                    dotCellLineData.push("00" + data.substring(1, 3) + "00" + data.substring(5, 7));
                });
            }
        } else {
            if (tempDotCellLineData[this.lineCount]) {
                tempDotCellLineData[this.lineCount].forEach(data => {
                    dotCellLineData.push("00" + data.substring(0, 2) + "00" + data.substring(4, 6));
                });
            }
        }
        this.lineCount++;
    }

    handle1101(tempDotCellLineData, dotCellLineData) {
        if (tempDotCellLineData[this.lineCount]) {
            let tempData1 = [], tempData2 = [], tempData3 = [], tempData4 = [];
            tempDotCellLineData[this.lineCount].forEach(data => {
                tempData1.push(data.substring(0, 2));
                tempData2.push(data.substring(4, 6));
            });
            if (tempDotCellLineData[this.lineCount + 1]) {
                tempDotCellLineData[this.lineCount + 1].forEach(data => {
                    tempData3.push(data.substring(3, 4) + "0");
                    tempData4.push(data.substring(7, 8) + "0");
                });
            } else {
                tempDotCellLineData[this.lineCount].forEach(() => {
                    tempData3.push("000");
                    tempData4.push("000");
                });
            }
            tempData1.forEach((data, i) => {
                dotCellLineData.push(tempData3[i] + data + tempData4[i] + tempData2[i]);
            });
        }
        this.lineCount++;
    }

    handle1110(tempDotCellLineData, dotCellLineData, numberOfPins) {
        if (numberOfPins != this.DOT_CELL_PIN) {
            if (tempDotCellLineData[this.lineCount]) {
                tempDotCellLineData[this.lineCount].forEach(data => {
                    dotCellLineData.push("0" + data.substring(1, 4) + "0" + data.substring(5, 8));
                });
            }
        } else {
            if (tempDotCellLineData[this.lineCount]) {
                tempDotCellLineData[this.lineCount].forEach(data => {
                    dotCellLineData.push("0" + data.substring(0, 3) + "0" + data.substring(4, 7));
                });
            }
        }
        this.lineCount++;
    }

    setDisplayMode(mode) {
        this.dotPadSendModule.setDisplayMode(mode);
    }

    getDisplayMode() {
        return this.dotPadSendModule.getDisplayMode();
    }
}

export {
    DotpadSDK
};

